.copyright{
    background-color: #212830;
    .footer_bt{
        padding: 15px 0;
        margin: 0 auto;
        color: #ffffff;
    }
    .tx_center{
        text-align: center;
    }
    .tx_right{
        text-align: right;
    }
    .color_6{
        color: #666666;
        margin-top: 10px;
        span{
            cursor: pointer;
        }
        a{
            margin-left: 10px;
            color: #666666;
            img{
                width: 15px;
                height: 15px;
                margin: 0 5px;
            }
        }
    }
}
.ant-btn-primary{
    background-color: #1C73BD;
}
@media screen and (max-width: 1279px){
    .copyright{
        .tx_right{
            text-align: right;
        }
        .media_con{
            width: auto;
            margin: 0 15px;
        }
    }
}
@media screen and (max-width:767px){
    .copyright{
        .tx_right{
            text-align: left;
        }
        .media_con{
            width: auto;
            margin: 0 15px;
        }
    }
}
