.nav{
    height: 100%;
    .logo{
        padding-top: 16px;
        a{
            display: block;
            width: 181px;
            height: 39px;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    .nav_list{
        text-align: right;
        a{
            display: inline-block;
            line-height: 64px;
            padding:  0 25px;
            transition: all 0.5s ease;
            position: relative;
            color: #ffffff;
            &:hover{
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
            }
        }
        .active_nav{
            font-size: 16px;
            color: #ffffff;
            font-weight: bold;
        }
    }
    .lang{
        display: inline-block;
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 16px;
        border: 1px solid #ffffff;
        background-color: #5870FE;
        margin-top: 20px;
        cursor: pointer;
        span{
            height: 100%;
            width: 50%;
            border-radius: 16px 0 0 16px;
            display: inline-block;
            color: #ffffff;
        }
        span:nth-of-type(2){
            border-radius: 0 16px 16px 0;
        }
        .active_Lang{
            color: #5870FE;
            background-color: #ffffff;
        }
    }
}
.nav_phone_wrap{
    display: none;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(0, 10, 66, 0.1);
    .nav_phone{
        width: 100%;
        text-align: center;
        box-shadow: 0px 2px 8px 0px rgba(153, 157, 177, 0.3);
        .logo{
            width: 140px;
            a{
                width: 140px;
                img{
                    width: 100%;
                }
            }
        }
        .menu{
            flex: 1;
            text-align: right;
        }
        .ant-col{
            line-height: 48px;
            padding: 0 15px;
            .iconfont{
                font-size: 24px;
                color: #017AC6;
                cursor: pointer;
            }
            a{
                color: #999999;
                display: block;
            }
            .active_nav{
                color: #5870FE;
                font-weight: bold;
            }
        }
    }
    .ant-list{
        margin-top: -4px;
        background-color: #ffffff;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        .ant-list-item{
            padding: 0;
           a{
               width: 100%;
                padding: 8px 16px;
                display: block;
                color: #5870FE;
            } 
        }
    }
    .menu_list{
        display: none;
        .lang{
            text-align: center;
            padding: 10px 0;
            line-height: 24px;
            background-color: #ffffff;
            span{
                display: inline-block;
                width: 60px;
                border: 1px solid #5870FE;
                color: #5870FE;
                background-color: #ffffff;
                cursor: pointer;
            }
            .active_Lang{
                color: #ffffff;
                background-color: #5870FE;
            }
        }
    }
    .menu_list_show{
        display: block;
    }
}
@media screen and (max-width:1200px){
    .nav{
        .nav_list{
            a{
                padding: 0 25px;
            }
        }
    }
}
@media screen and (max-width:992px){
    .nav{
        display: flex;
        .logo{
            padding-top: 22px;
            a{
                width: 120px;
                height: auto;
            }    
        }
        .nav_list{
            a{
                padding: 0 15px;
            }
        }
    }
}
@media screen and (max-width:767px){
    .nav{
        display: none;
        .nav_list{
            a{
                padding: 0 15px;
            }
        }
    }
    .nav_phone_wrap{
        display: block;
    }
    
}
@media screen and (max-width: 576px){

}