.footer_bd{
    // height: 314px;
    padding: 50px 0;
    text-align: center;
    .footer_bd_con{
        margin: 0 30px;
        h6{
            font-size: 26px;
            font-weight: bold;
            color: #5870FE;
            margin-bottom: 50px;
        }
        .ant-col{
            margin-bottom: 20px;
			.wx_pic{
				display: flex;
				justify-content: center;
				.ft_pic_wrap{
					margin-right: 20px;
				}
			}
        }
        .ft_pic{
            width: 70px;
            margin: 0 auto 20px;
            img{
                width: 100%;
            }
        }
        p{
            margin-bottom: 0;
            color: #45526B;
            font-size: 16px;
        }
        p:nth-of-type(1){
            font-size: 12px;
            color: #596C8E;
        }
    }
}
@media screen and (max-width:992px){
    .footer_bd{
        padding: 15px 0;
        .footer_bd_con{
            h6{
                font-size: 18px;
                margin-bottom: 20px;
            }
            .ft_pic{
                width: 40px;
            }
            .wx_code{
                width: 50px;
            }
        }
    }
}
@media screen and (max-width:576px){
    .footer_bd{
        padding: 15px 0;
        .footer_bd_con{
            .ant-row{
                justify-content: center;
            }
        }
    }
}
