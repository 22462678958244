.ant-layout{
  background-color: #ffffff;
  .ant-layout-header{
    height: 74px;
    background: #5870FE;
    padding: 0px 80px;
    // box-shadow: 0px 10px 20px 0px rgba(0, 10, 66, 0.1);
  }
  .ant-layout-content{
    margin-top: 64px;
  }
  .ant-layout-footer{
    padding: 0px;
    color: #45526B;
    background-color: #F5F7FB;
  }
  
}
@media screen and (max-width:767px){
  .ant-layout{
    .ant-layout-header{
      height: 48px;
      padding: 0;
      // position: relative !important;
      background: #ffffff;
    }
    .ant-layout-content{
      margin-top: 48px;
    }
  }
  
}
